var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introducePage"},[_vm._m(0),_c('div',{staticClass:"section-main"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"main-table"},[_c('div',{staticClass:"table-flex"},[_vm._m(3),_c('div',{staticClass:"table-tbody"},_vm._l((_vm.tableList),function(item,index){return _c('div',{key:index,staticClass:"tbody-th"},[_c('div',{staticClass:"tbody-th-td flex1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"tbody-th-td flex1"},[_vm._v(_vm._s(item.sex))]),_c('div',{staticClass:"tbody-th-td flex2"},[_vm._v(_vm._s(item.both))]),_c('div',{staticClass:"tbody-th-td flex2"},[_vm._v(_vm._s(item.political))]),_c('div',{staticClass:"tbody-th-td flex2"},[_vm._v(_vm._s(item.job))]),_c('div',{staticClass:"tbody-th-td flex2"},[_vm._v(_vm._s(item.phone))])])}),0)])]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-aside"},[_c('p',[_vm._v("宁波市心理")]),_c('p',[_vm._v("援助协会简介")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('p',[_vm._v(" 宁波市心理援助协会是国内首家登记注册的专业心理援助社团。现有取得国家心理咨询师职业资格及具有社会工作、医学、教育学等相关领域专业资质的会员150余名，其中具有硕士学位的会员占比率为35%，社会工作师占比率为15%。协会有专兼职工作人员5名，组织制度完备。协会下设普及与交流、危机干预、职业伦理、标准化与水平评价4个专业委员会，是登记管理机关认定的5A级社会组织”和“慈善组织”。 ")]),_c('p',[_vm._v(" 长期以来，协会始终贯彻“知行合一，服务社会”的团体理念，执行公益创投项目和政府购买服务项目50余项，服务内容涉及青少年成长、和谐婚姻家庭、老年心理保健、特定职业人群心理疏导以及社会组织能力提升和等级评估等，创新性地连续六年举办“宁波市心理学术周活动。同时，协会组织会员常态化地深入社区、企事业单位、学校等开展内容丰富的心理健康宣讲和咨询活动。 ")]),_c('p',[_vm._v(" 截至目前，协会累计组织和参与各类公益性心理援助活动700余次，总服务达9万人次以上，设计的公益项目曾获宁波市志愿服务项目大赛金奖，宁波市第四届、第五届公益项目设计大赛二等奖。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-video"},[_c('audio',{attrs:{"controls":"","id":"audios","height":"50","autoplay":"autoplay","src":require("./audio/introducePageTwo_bg.mp3")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-thead"},[_c('div',{staticClass:"thead-th flex1"},[_vm._v("姓名")]),_c('div',{staticClass:"thead-th flex1"},[_vm._v("性别")]),_c('div',{staticClass:"thead-th flex2"},[_vm._v("出生年月")]),_c('div',{staticClass:"thead-th flex2"},[_vm._v("政治面貌")]),_c('div',{staticClass:"thead-th flex2"},[_vm._v("职务")]),_c('div',{staticClass:"thead-th flex2"},[_vm._v("联系方式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-picture"},[_c('img',{attrs:{"src":require("./img/introducePageTwo_show_one.jpg"),"al":"","t":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-picture"},[_c('img',{attrs:{"src":require("./img/introducePageTwo_show_two.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-picture"},[_c('img',{attrs:{"src":require("./img/introducePageTwo_show_three.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-picture"},[_c('img',{attrs:{"src":require("./img/introducePageTwo_show_four.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-picture"},[_c('img',{attrs:{"src":require("./img/introducePageTwo_show_five.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-picture"},[_c('img',{attrs:{"src":require("./img/introducePageTwo_show_sex.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-picture"},[_c('img',{attrs:{"src":require("./img/introducePageTwo_show_seven.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-picture"},[_c('img',{attrs:{"src":require("./img/introducePageTwo_show_eight.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-picture"},[_c('img',{attrs:{"src":require("./img/introducePageTwo_show_nine.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-picture"},[_c('img',{attrs:{"src":require("./img/introducePageTwo_show_ten.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }