<template>
  <div class="introducePage">
    <div class="section-aside">
      <p>宁波市心理</p>
      <p>援助协会简介</p>
    </div>
    <div class="section-main">
      <div class="main-content">
        <p>
          宁波市心理援助协会是国内首家登记注册的专业心理援助社团。现有取得国家心理咨询师职业资格及具有社会工作、医学、教育学等相关领域专业资质的会员150余名，其中具有硕士学位的会员占比率为35%，社会工作师占比率为15%。协会有专兼职工作人员5名，组织制度完备。协会下设普及与交流、危机干预、职业伦理、标准化与水平评价4个专业委员会，是登记管理机关认定的5A级社会组织”和“慈善组织”。
        </p>
        <p>
          长期以来，协会始终贯彻“知行合一，服务社会”的团体理念，执行公益创投项目和政府购买服务项目50余项，服务内容涉及青少年成长、和谐婚姻家庭、老年心理保健、特定职业人群心理疏导以及社会组织能力提升和等级评估等，创新性地连续六年举办“宁波市心理学术周活动。同时，协会组织会员常态化地深入社区、企事业单位、学校等开展内容丰富的心理健康宣讲和咨询活动。
        </p>
        <p>
          截至目前，协会累计组织和参与各类公益性心理援助活动700余次，总服务达9万人次以上，设计的公益项目曾获宁波市志愿服务项目大赛金奖，宁波市第四届、第五届公益项目设计大赛二等奖。
        </p>
      </div>

      <div class="main-video">
        <audio
          controls
          id="audios"
          height="50"
          autoplay="autoplay"
          src="./audio/introducePageTwo_bg.mp3"
        ></audio>
      </div>

      <div class="main-table">
        <div class="table-flex">
          <div class="table-thead">
            <div class="thead-th flex1">姓名</div>
            <div class="thead-th flex1">性别</div>
            <div class="thead-th flex2">出生年月</div>
            <div class="thead-th flex2">政治面貌</div>
            <div class="thead-th flex2">职务</div>
            <div class="thead-th flex2">联系方式</div>
          </div>
          <div class="table-tbody">
            <div
              class="tbody-th"
              v-for="(item, index) in tableList"
              :key="index"
            >
              <div class="tbody-th-td flex1">{{ item.name }}</div>
              <div class="tbody-th-td flex1">{{ item.sex }}</div>
              <div class="tbody-th-td flex2">{{ item.both }}</div>
              <div class="tbody-th-td flex2">{{ item.political }}</div>
              <div class="tbody-th-td flex2">{{ item.job }}</div>
              <div class="tbody-th-td flex2">{{ item.phone }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="main-picture">
        <img src="./img/introducePageTwo_show_one.jpg" al t="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageTwo_show_two.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageTwo_show_three.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageTwo_show_four.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageTwo_show_five.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageTwo_show_sex.jpg" alt="" />
      </div>
      <div class="main-picture">
        <img src="./img/introducePageTwo_show_seven.jpg" alt="" />
      </div>
      <div class="main-picture">
        <img src="./img/introducePageTwo_show_eight.jpg" alt="" />
      </div>
      <div class="main-picture">
        <img src="./img/introducePageTwo_show_nine.jpg" alt="" />
      </div>
      <div class="main-picture">
        <img src="./img/introducePageTwo_show_ten.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableList: [
        {
          name: "夏雨",
          sex: "男",
          both: "1972.09",
          political: "农工党党员",
          job: "会长",
          phone: "13957481589",
        },
        {
          name: "周维",
          sex: "女",
          both: "1974.11",
          political: "群众",
          job: "副会长",
          phone: "13605741787",
        },
        {
          name: "苏为吉",
          sex: "男",
          both: "1969.04",
          political: "中共党员",
          job: "秘书长",
          phone: "13175176070",
        },
        {
          name: "董天逸",
          sex: "女",
          both: "1987.12",
          political: "群众",
          job: "副秘书长",
          phone: "13600628748",
        },
        {
          name: "陈佳",
          sex: "女",
          both: "1984.01",
          political: "农工党党员",
          job: "副秘书长",
          phone: "18868685946",
        },
      ],
      timer: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const that = this;
      //--创建页面监听，等待微信端页面加载完毕 触发音频播放
      document.addEventListener("WeixinJSBridgeReady", function () {
        that.$nextTick(() => {
          document.getElementById("audios").play();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.introducePage {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 378px 0 80px;
  background: url(./img/introducePage_bg.png) no-repeat top left;
  background-size: 100% auto;
  background-color: #dfeeff;
  .section-aside {
    position: absolute;
    top: 160px;
    left: 36px;
    font-size: 72px;
    color: #ffffff;
    line-height: 84px;
    text-shadow: 5px 2px #4a7196;
    p:last-child {
      padding-left: 40px;
    }
  }
  .section-main {
    box-sizing: border-box;
    padding: 38px 30px 0px;
    width: 100%;
    background: url(./img/introducePage_across.png) no-repeat top center;
    background-size: calc(100% - 20px) 80px;
    .main-content {
      // width: 100%;
      padding: 50px 30px 56px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      font-size: 28px;
      line-height: 40px;
      color: rgba(0, 0, 0, 0.85);
      // text-align-last: justify;
      text-align: justify;
      text-indent: 2em;
    }
    .main-video {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 40px 22px 40px;
      text-align: center;
      height: 100px;
      ::v-deep video {
        width: 100%;
        height: 100%;
        object-fit: fill;
        background: #fff;
      }
    }
    .main-table {
      position: relative;
      margin-top: 30px;
      padding: 50px 30px 0px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      .table-flex {
        overflow: auto;
        padding-bottom: 50px;
      }
      .table-thead {
        white-space: nowrap;
        > div {
          display: inline-block;
          &.flex1 {
            width: 120px;
          }
          &.flex2 {
            width: 240px;
          }
          font-size: 28px;
          text-align: center;
          font-weight: bold;
        }
      }
      .table-tbody {
        .tbody-th {
          white-space: nowrap;
          > div {
            display: inline-block;
            &.flex1 {
              width: 120px;
            }
            &.flex2 {
              width: 240px;
            }
            font-size: 24px;
            line-height: 48px;
            text-align: center;
            white-space: normal;
          }
        }
      }
    }
    .main-picture {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 68px 22px 94px;
      img {
        width: 100%;
        // width: 646px;
        // height: 486px;
      }
      &:not(:last-child)::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -68px;
        left: 28px;
        width: 634px;
        height: 106px;
        background: url(./img/introducePage_vertical.png) no-repeat top center;
        background-size: 100% 100%;
        z-index: 1;
      }
    }
  }
}
</style>
